import React, { useContext, useEffect, useState } from "react";
import qs from "qs";
import { GlobalContextState, GlobalContextDispatch } from "../../context/globalContext";
import { baseUrl } from "../../config";
import parse from "html-react-parser";
import Tooltip from '@mui/material/Tooltip';

export default function Subscription() {
  const dispatch = useContext(GlobalContextDispatch);
  const user = useContext(GlobalContextState).user;
  const token = useContext(GlobalContextState).token;
  const urlStripeData = `${baseUrl}/api/stripe-customers`;

  const [accountConfirmed, setAccountConfirmed] = React.useState(false);
  const [stripeData, setStripeData] = React.useState(null);
  const [currentPlan, setCurrentPlan] = React.useState('');
  const [selectedTime, setSelectedTime] = React.useState('monthly');
  const [currentPlanTokens, setCurrentPlanTokens] = React.useState(0);
  const [stripeProducts, setStripeProducts] = React.useState([]);
  const [deleteStatus, setDeleteStatus] = React.useState(null);
  const [changeSubscription, setChangeSubscription] = React.useState(false);
  const [popupCancelSubscription, setPopupCancelSubscription] = React.useState(false);
  const [updateSubsTokens, setUpdateSubsTokens] = React.useState(null);
  const [updateSubsPlan, setUpdateSubsPlan] = React.useState(null);
  const [updateSubsPriceId, setUpdateSubsPriceId] = React.useState(null);
  const [updateTotalAmout, setUpdateTotalAmout] = React.useState(null);
  const [updateSubs, setUpdateSubs] = React.useState(null);
  const [priceList, setPriceList] = React.useState([]);
  const [userSubscription, setUserSubscription] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  
  useEffect(() => {
    // Check if url has accountConfirmed query
    const urlParams = new URLSearchParams(window.location.search);
    const accountConfirmed = urlParams.get('email-confirmed');
    if(accountConfirmed === 'done'){
      setAccountConfirmed(true);

      setTimeout(() => {
        window.history.replaceState({}, document.title, window.location.pathname);
        setAccountConfirmed(false);
      } , 10000);
    }

    const initFetchData = async () => {
      await fetchStripeData();
      await fetchStripeProducts();
      await fetchPriceList();
    };

    initFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(user) {
      setUserSubscription(user.subscription);
      setUserEmail(user.email);
    }
  }, [user, token, dispatch]);

  useEffect(() => {
    if(deleteStatus === 'success'){
      const timerDelete = setTimeout(() => {
        setDeleteStatus(null);
      }, 5000);
      return () => clearTimeout(timerDelete);
    }
  }, [deleteStatus]);

  useEffect(() => {
    if(updateSubs === 'success'){
      const timerUpdate = setTimeout(() => {
        setUpdateSubs(null);
      }, 5000);
      return () => clearTimeout(timerUpdate);
    }
  }, [updateSubs]);

  async function fetchStripeData() {
    try {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);

      const query = qs.stringify({
        filters: {
            user: {
                username: {
                    $eq: user.username,
                },
            },
          },
      }, {
          encodeValuesOnly: true,
      });

      const fetchUrl = `${urlStripeData}?${query}`;
      const response = await fetch(fetchUrl, {
          method: 'GET',
          headers: myHeaders,
      });

      if (response.ok) {
        const data = await response.json();
        if (data.data.length > 0) {
          setStripeData(data.data[0]);
          setCurrentPlan(data.data[0].attributes.subsPlan + '_' + data.data[0].attributes.subsTokens);
          console.log(data.data[0].attributes.subsPlan + '_' + data.data[0].attributes.subsTokens)
          setSelectedTime(data.data[0].attributes.subsPlan);
          setCurrentPlanTokens(data.data[0].attributes.subsTokens);
        }
      }
    } catch (error) {
      console.error('Failed to fetch data', error);
    }
  }

  async function fetchStripeProducts() {
    try {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);

      const response = await fetch(`${baseUrl}/api/stripe/products`, {
        method: 'GET',
        headers: myHeaders,
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data)
        if (data.length > 0) {
          const filteredAndSortedData = data.filter(item => item.active)
            .sort((a, b) => a.metadata.pos - b.metadata.pos);

          setStripeProducts(filteredAndSortedData);
        }
      }
    } catch (error) {
      console.error('Failed to fetch data', error);
    }
  }
  
  async function fetchPriceList() {
    try {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);

      const response = await fetch(`${baseUrl}/api/price-lists?filters[enable][$eq]=true`, {
        method: 'GET',
        headers: myHeaders,
      });

      if (response.ok) {
        const data = await response.json();
        if (data.data && data.data.length > 0) {
          let responsePriceList = data.data.map((item) => {
            return item.attributes;
          });
          setPriceList(responsePriceList);
        }
      }
    } catch (error) {
      console.error('Failed to fetch price list', error);
    }
  }

  function convertCurrency(amountInCents, currency) {
    const amountInDollars = amountInCents / 100;
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
    }).format(amountInDollars);
  }

  async function startPayment(priceId, subsTokens, subsPlan, amount) {
    if(currentPlan){
      setChangeSubscription(true);
      setUpdateSubsTokens(subsTokens);
      setUpdateSubsPlan(subsPlan);
      setUpdateSubsPriceId(priceId);
      setUpdateTotalAmout(amount);
      return;
    }

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const response = await fetch(`${baseUrl}/api/stripe/create-checkout-session`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({ 
        priceId: priceId,
        subsTokens: subsTokens,
        updateType: 'new',
        subsPlan: subsPlan,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      if(data.url){
        window.location.href = data.url;
      }
    }
  }

  async function updatePaymentMethod() {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const response = await fetch(`${baseUrl}/api/stripe/get-update-url-subscription`, {
      method: 'GET',
      headers: myHeaders
    });

    if (response.ok) {
      const data = await response.json();
      if(data.url){
        window.location.href = data.url;
      }
    }
  }

  async function changeSubscriptionPlan() {
    setChangeSubscription(false);

    let updateType = ''
    if(currentPlanTokens !== 0){
      updateType = 'downgrade'
      if(updateSubsTokens > currentPlanTokens){
        updateType = 'upgrade'
      }
    }
    
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const response = await fetch(`${baseUrl}/api/stripe/create-checkout-session`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({ 
        priceId: updateSubsPriceId,
        subsTokens: updateSubsTokens,
        updateType: updateType,
        subsPlan: updateSubsPlan,
        totalAmount: updateTotalAmout,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      if(data.subscriptionId){
        setUpdateSubs('success');
        setCurrentPlan(updateSubsPlan + '_' + updateSubsTokens);
      }
    }
  }

  async function cancelSubscription() {
    setPopupCancelSubscription(false);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const response = await fetch(`${baseUrl}/api/stripe/cancel-subscription`, {
      method: 'GET',
      headers: myHeaders
    });

    if (response.ok) {
      const data = await response.json();
      if(data.success){
        // Display success message
        setDeleteStatus('success');
        setCurrentPlan('');
      }
    }
  }

  return (
    <>
      <div className="px-14 flex justify-between items-center pt-8">
        <div>
          <h2 className="text-2xl font-bold text-white text-3xl">Subscription</h2>
        </div>
        <div>
        {stripeData && (
        <button 
          onClick={() => updatePaymentMethod()}
          className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">Update Payment Method</button>
        )}
        </div>
      </div>
      <div className="px-14 py-4">
        {accountConfirmed && (
          <div className="p-4 mb-4 text-sm text-green-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-green-400" role="alert">
            <span className="font-medium">Your account has been confirmed. You can now choose a plan to get started.</span>
          </div>
        )}
        {userSubscription === false && stripeData && (
          <div className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
            <span className="font-medium">We can't charge you because you don't have a payment method. Please add a payment method to your account.</span>
          </div>
        )}
        {deleteStatus === 'success' && (
          <div className="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-green-800 dark:text-green-400" role="alert">
            <span className="font-medium">Your subscription has been cancelled successfully.</span>
          </div>
        )}
        {updateSubs === 'success' && (
          <div className="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-green-800 dark:text-green-400" role="alert">
            <span className="font-medium">Your subscription has been changed successfully.</span>
          </div>
        )}
        <div className="rounded bg-gray-800 p-4 py-6 mb-6">
          <div className="w-full">
            <h2 className="text-3xl font-bold text-gray-900 lg:font-extrabold lg:text-4xl lg:leading-snug dark:text-white lg:text-center 2xl:px-48">
              Choose the right plan for your business
            </h2>
            <p className="mb-8 text-lg text-gray-500 dark:text-gray-400 lg:text-center">Don't worry, you can change or cancel your plan at any time.</p>
            <div className="max-w-2xl mx-auto mb-8 text-center text-gray-500 dark:text-gray-400">
              <ul className="text-sm font-medium text-center text-gray-500 rounded-lg shadow sm:flex dark:divide-gray-700 dark:text-gray-400">
                  <li className="w-full focus-within:z-10">
                      <a href="#" 
                        onClick={() => setSelectedTime('monthly')}
                        className={`inline-block w-full p-4 text-gray-900 border-r rounded-l-lg focus:outline-none text-white ${selectedTime === 'monthly' ? 'bg-gray-600' : 'bg-gray-700'}`}>Monthly</a>
                  </li>
                  <li className="w-full focus-within:z-10">
                      <a href="#" 
                        onClick={() => setSelectedTime('yearly')}
                        className={`inline-block w-full p-4 text-gray-900 border-r rounded-r-lg focus:outline-none text-white ${selectedTime === 'yearly' ? 'bg-gray-600' : 'bg-gray-700'}`}>Yearly</a>
                  </li>
              </ul> 
            </div>
            {selectedTime === 'monthly' ? (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-x-8 px-4">
                {stripeProducts.map((product, index) => {
                  if(product.active && product.metadata.plan === 'monthly'){
                    let canShow = true
                    const productForEmail = product.metadata.email ? product.metadata.email : '';
                    if(productForEmail !== '' && productForEmail !== userEmail){
                      canShow = false;
                    }
                    const thisPlan = product.metadata.plan + '_' + product.metadata.tokens;
                    if(canShow){
                      return (
                        <div key={index} className={`mb-8 rounded-lg p-8 ${currentPlan === thisPlan ? 'bg-green-700' : 'bg-gray-700'}`}>
                          <h3 className="mb-1 text-lg font-bold text-gray-900 dark:text-white">
                            {product.name}
                          </h3>
                          <span className="block mb-4 text-4xl font-extrabold text-gray-900 dark:text-white">
                            {convertCurrency(product.default_price.unit_amount, product.default_price.currency)}
                          </span>
                          {stripeData && stripeData.attributes && currentPlan === thisPlan && (
                            <div className="mb-4"><strong className="w-40 inline-block">Next billing date:</strong> {stripeData.attributes.nextBillingDate}</div>
                          )}
                          <p className={`mb-4 text-sm ${currentPlan === thisPlan ? 'text-white' : 'dark:text-gray-400'}`}>
                            {product.description}&nbsp;
                          </p>
                          {currentPlan !== thisPlan ? (
                            <button 
                              onClick={() => startPayment(product.default_price.id, product.metadata.tokens, product.metadata.plan, product.default_price.unit_amount)}
                              className="items-center justify-center w-full px-6 py-2 mb-3 text-base font-medium text-center text-white bg-blue-700 dark:bg-blue-600 hover:bg-blue-800 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-300 dark:hover:bg-blue-700 md:mr-5 md:mb-0">
                              {currentPlan !== '' ? 'Switch Plan' : 'Get Started'}
                            </button>
                          ) : (
                            <button 
                              onClick={() => setPopupCancelSubscription(true)}
                              className="items-center justify-center w-full px-6 py-2 mb-3 text-base font-medium text-center text-white bg-red-700 dark:bg-red-600 hover:bg-red-800 rounded-lg focus:outline-none focus:ring-4 focus:ring-red-300 dark:hover:bg-red-700 md:mr-5 md:mb-0">
                              Cancel Subscription
                            </button>
                          )}
                        </div>
                      );
                    }
                  }
                })}
              </div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-x-8 px-4">
                {stripeProducts.map((product, index) => {
                  if(product.active && product.metadata.plan === 'yearly'){
                    let canShow = true
                    const productForEmail = product.metadata.email ? product.metadata.email : '';
                    if(productForEmail !== '' && productForEmail !== userEmail){
                      canShow = false;
                    }
                    const thisPlan = product.metadata.plan + '_' + product.metadata.tokens;
                    if(canShow){
                      return (
                        <div key={index} className={`mb-8 rounded-lg p-8 ${currentPlan === thisPlan ? 'bg-green-700' : 'bg-gray-700'}`}>
                          <h3 className="mb-1 text-lg font-bold text-gray-900 dark:text-white">
                            {product.name}
                          </h3>
                          <span className="block mb-4 text-4xl font-extrabold text-gray-900 dark:text-white">
                            {convertCurrency(product.default_price.unit_amount, product.default_price.currency)}
                          </span>
                          <p className={`mb-4 text-sm ${currentPlan === thisPlan ? 'text-white' : 'dark:text-gray-400'}`}>
                            {product.description}&nbsp;
                          </p>
                          {currentPlan !== thisPlan ? (
                            <button 
                              onClick={() => startPayment(product.default_price.id, product.metadata.tokens, product.metadata.plan, product.default_price.unit_amount)}
                              className="items-center justify-center w-full px-6 py-2 mb-3 text-base font-medium text-center text-white bg-blue-700 dark:bg-blue-600 hover:bg-blue-800 rounded-lg focus:outline-none focus:ring-4 focus:ring-blue-300 dark:hover:bg-blue-700 md:mr-5 md:mb-0">
                              {currentPlan !== '' ? 'Switch Plan' : 'Get Started'}
                            </button>
                          ) : (
                            <button 
                              onClick={() => setPopupCancelSubscription(true)}
                              className="items-center justify-center w-full px-6 py-2 mb-3 text-base font-medium text-center text-white bg-red-700 dark:bg-red-600 hover:bg-red-800 rounded-lg focus:outline-none focus:ring-4 focus:ring-red-300 dark:hover:bg-red-700 md:mr-5 md:mb-0">
                              Cancel Subscription
                            </button>
                          )}
                        </div>
                      );
                    }
                  }
                })}
              </div>
            )}
          </div>
        </div>
        <div className="rounded bg-gray-800 p-4 py-6 mb-6">
          <div className="w-full">
            <h2 className="mb-4 text-3xl font-bold text-gray-900 lg:font-extrabold lg:text-4xl lg:leading-snug dark:text-white lg:text-center 2xl:px-48">
              Pricing list
            </h2>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3 font-bold text-white">
                            Service Name
                            </th>
                            <th scope="col" className="px-6 py-3 font-bold text-white">
                            Description
                            </th>
                            <th scope="col" className="px-6 py-3 whitespace-nowrap w-[1%] font-bold text-white">
                            Success Price
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {priceList.map((item, index) => {
                          if(item.category === 'Pre'){
                            return (
                              <tr key={item.serviceCode} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700  border-solid hover:bg-gray-50 dark:hover:bg-gray-600">
                                <td className="px-6 py-4">
                                  {parse(item.serviceTitle)}
                                  {item.beta && (
                                    <Tooltip title="On Beta-Testing, this won’t be charged" arrow placement="top">
                                      <span className="inline-flex ml-3 items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-yellow-600/20 ring-inset cursor-help">Beta</span>
                                    </Tooltip>
                                  )}
                                </td>
                                <td className="px-6 py-4">{parse(item.serviceDescription)}</td>
                                <td className="px-6 py-4 text-right">{item.successPrice} {item.successPrice > 1 ? 'tokens' : 'token'}</td>
                              </tr>
                            )
                          }
                        })}
                        {priceList.map((item, index) => {
                          if(item.category === 'Est'){
                            return (
                              <tr key={item.serviceCode} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700  border-solid hover:bg-gray-50 dark:hover:bg-gray-600">
                                <td className="px-6 py-4">
                                  {parse(item.serviceTitle)}
                                  {item.beta && (
                                    <Tooltip title="On Beta-Testing, this won’t be charged" arrow placement="top">
                                      <span className="inline-flex ml-3 items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-yellow-600/20 ring-inset cursor-help">Beta</span>
                                    </Tooltip>
                                  )}
                                </td>
                                <td className="px-6 py-4">{parse(item.serviceDescription)}</td>
                                <td className="px-6 py-4 text-right">{item.successPrice} {item.successPrice > 1 ? 'tokens' : 'token'}</td>
                              </tr>
                            )
                          }
                        })}
                        {priceList.map((item, index) => {
                          if(item.category === 'Post'){
                            return (
                              <tr key={item.serviceCode} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700  border-solid hover:bg-gray-50 dark:hover:bg-gray-600">
                                <td className="px-6 py-4">
                                  {parse(item.serviceTitle)}
                                  {item.beta && (
                                    <Tooltip title="On Beta-Testing, this won’t be charged" arrow placement="top">
                                      <span className="inline-flex ml-3 items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-yellow-600/20 ring-inset cursor-help">Beta</span>
                                    </Tooltip>
                                  )}
                                </td>
                                <td className="px-6 py-4">{parse(item.serviceDescription)}</td>
                                <td className="px-6 py-4 text-right">{item.successPrice} {item.successPrice > 1 ? 'tokens' : 'token'}</td>
                              </tr>
                            )
                          }
                        })}
                        {priceList.map((item, index) => {
                          if(item.category === 'Common'){
                            return (
                              <tr key={item.serviceCode} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700  border-solid hover:bg-gray-50 dark:hover:bg-gray-600">
                                <td className="px-6 py-4">
                                  {parse(item.serviceTitle)}
                                  {item.beta && (
                                    <Tooltip title="On Beta-Testing, this won’t be charged" arrow placement="top">
                                      <span className="inline-flex ml-3 items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-yellow-600/20 ring-inset cursor-help">Beta</span>
                                    </Tooltip>
                                  )}
                                </td>
                                <td className="px-6 py-4">{parse(item.serviceDescription)}</td>
                                <td className="px-6 py-4 text-right">{item.successPrice} {item.successPrice > 1 ? 'tokens' : 'token'}</td>
                              </tr>
                            )
                          }
                        })}
                    </tbody>
                </table>
            </div>

          </div>
        </div>
      </div>
      {popupCancelSubscription && (
        <>
          <div id="popup-modal" tabIndex="-1" className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full flex">
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <button 
                    onClick={() => setPopupCancelSubscription(false)}
                    type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal">
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div className="p-4 md:p-5 text-center">
                        <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to cancel your subscription?</h3>
                        <button data-modal-hide="popup-modal" 
                          onClick={() => cancelSubscription()}
                          type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                            Yes, I'm sure
                        </button>
                        <button 
                          onClick={() => setPopupCancelSubscription(false)}
                          data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">No, cancel</button>
                    </div>
                </div>
            </div>
          </div>
          <div className="bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40"></div>
        </>
      )}


      {changeSubscription && (
        <>
          <div id="popup-modal" tabIndex="-1" className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full flex">
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <button 
                    onClick={() => setChangeSubscription(false)}
                    type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal">
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div className="p-4 md:p-5 text-center">
                        <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to change your subscription?</h3>
                        <button data-modal-hide="popup-modal" 
                          onClick={() => changeSubscriptionPlan()}
                          type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                            Yes, I'm sure
                        </button>
                        <button 
                          onClick={() => setChangeSubscription(false)}
                          data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">No, cancel</button>
                    </div>
                </div>
            </div>
          </div>
          <div className="bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40"></div>
        </>
      )}
    </>
  );
}
